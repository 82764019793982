/* CSS */
.imageOverlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -8%;
    padding-top: 10%;
}
.closeButton {
    position: fixed;
    top: 5%;
    border-radius: 22px;
    border-style: none;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    font-size: 14px;
    line-height: 20px; 
    padding: 5px 10px;
    margin-left: 73%;
    width: 100%;
    min-width: 33px;  
    max-width: 33px;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    max-height: 100%;
    gap: 20px;
}
.enlargedImage {
    border: 2px solid #FFFFFF;
    width: auto !important;
    height: 70% !important;
    object-fit: contain !important;
}

.imageTitle {
    font-family: 'Telegraf';
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}