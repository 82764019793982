@font-face {
    font-family: 'Telegraf';
    src: url('../assets/fonts/Telegraf-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Telegraf';
    src: url('../assets/fonts/Telegraf-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Telegraf';
    src: url('../assets/fonts/Telegraf-Regular-400.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  