.totalUsers{
    position: absolute;
    font-size: 15px;
    margin-top: 9px;
    margin-left: 89px;
}

.totalVenues{
    position: absolute;
    text-align: left;
    font-size: 15px;
    margin-top: 9px;
    margin-left: 113px;
}

.totalPosts{
    position: absolute;
    font-size: 15px;
    margin-top: 9px;
    margin-left: 89px;
}